// We are using this constants inside webpack config
// therefore you have to forget about es6
const {
  DE,
  AT,
  IT,
  TR,
  CA,
  JP,
  HU,
  NO,
  SE,
  NL,
  BE,
  LU,
  DK,
  FI,
  IE,
  PL,
  RU,
  UK,
  US,
} = require('./countries')

const SALES_RUS = 'RUB'
const SALES_UK = 'GBP'
const SALES_EU = 'EUR'
const SALES_CA = 'CAD'
const SALES_DK = 'DKK'
const SALES_HU = 'HUF'
const SALES_JP = 'JPY'
const STRIPE_COUNTRY = 'GB'

/** @enum {string} */
const GOODS_TYPES = {
  dna: 'dna',
  biome: 'biome',
  microbiome: 'microbiome',
  wgs: 'wgs',
  bundle: '_bundle',
}

const DELIVERY_TYPES = {
  pickup: 1,
  courier: 2,
}

const SALES = {
  [SALES_RUS]: {
    precision: null,
    delivery: {
      default: DELIVERY_TYPES.courier,
      baseTypes: null,
      // [
      // {
      //   value: DELIVERY_TYPES.courier,
      //   code: 'courier',
      // },
      // {
      //   value: DELIVERY_TYPES.pickup,
      //   code: 'pickup',
      // },
      // ],
    },
  },
  [SALES_UK]: {
    precision: 2,
    delivery: {
      default: DELIVERY_TYPES.courier,
      baseTypes: null,
    },
  },
  [SALES_EU]: {
    precision: 2,
    delivery: {
      default: DELIVERY_TYPES.courier,
      baseTypes: null,
    },
  },
  [SALES_CA]: {
    precision: 2,
    delivery: {
      default: DELIVERY_TYPES.courier,
      baseTypes: null,
    },
  },
  [SALES_DK]: {
    precision: 2,
    delivery: {
      default: DELIVERY_TYPES.courier,
      baseTypes: null,
    },
  },
  [SALES_HU]: {
    precision: 2,
    delivery: {
      default: DELIVERY_TYPES.courier,
      baseTypes: null,
    },
  },
  [SALES_JP]: {
    precision: null,
    delivery: {
      default: DELIVERY_TYPES.courier,
      baseTypes: null,
    },
  },
}

const COUNTRIES_WITHOUT_DNA = [DE, AT, TR, US]
const COUNTRIES_WITH_DISTRIBUTION = [IT, DK]
const COUNTRIES_WITH_DYNAMIC_DISCOUNT = []
const COUNTRIES_WITHOUT_EMAIL_SUBSCRIPTION = [TR, JP]
const COUNTRIES_WITHOUT_SALES = [JP, CA, DE, SE, LU, FI, AT, PL, HU, NO]

const DYNAMIC_DISCOUNT_PROMO_EU = 'uktiereddiscount'
const DYNAMIC_DISCOUNT_PROMO_JP = 'jptiereddiscount'
const DYNAMIC_DISCOUNT_PROMO_RU = 'rutiereddiscount'

// =================================
// События
// =================================
/**
 * Коды событий - служат идентификаторами в разных местах,
 * а так же ключом в переводах
 * @enum {string}
 */
const EVENT_CODES = {
  BOILERPLATE: 'BOILERPLATE',
  CYBER_WEEK_23: 'cyberWeek23',
  VALENTINES_23: 'valentines23',
  FEBRUARY_23: 'february23',
  WOMENS_DAY_23: 'womensDay23',
  MARCH_PROMO_23: 'marchPromo23',
  HEALTH_DAY_23: 'healthDay23',
  NEW_REC_23: 'newRec23',
  DNA_DAY_23: 'dnaDay23',
  MAY_PROMO_23: 'mayPromo23',
  CHILD_DAY_23: 'childDay23',
  BIOME_DAY_23: 'biomaDay23',
  BIOME_BUNDLE_23: 'biomaBundle23',
  FAMILY_DAY_23: 'familyDay23',
  AUGUST_23: 'august23',
  WOMENS_DAY_24: 'womens_day_24',
  HEALTH_DAY_24: 'healthDay24',
  DNA_DAY_24: 'dnaDay24',
  KNOWLEDGE_DAY_24: 'knowledgeDay24',
  TEACHERS_DAY_24: 'teachersDay24',
  BLACK_FRIDAY_24: 'blackFriday24',
  PRE_NEW_YEAR_24: 'preNewYear24',
  CYBER_MONDAY_25: 'cyberMonday25',
}
// Чтобы не сломать старое, но проще мыслить в категории событий, а не скидок
const DISCOUNTS = EVENT_CODES
/**
 * @typedef {{
 *   code: string,
 *   schedule: {
 *     start: string,
 *     end: string,
 *   },
 *   countries: Array.<string>
 * }} PromoEvent
 */

/**
 * Все строится в первую очередь от событий, в честь которых организуются разные
 * скидки и показываются те или иные слайды/баннеры и т.д.
 */

/** @type {Object.<string, PromoEvent>} - События RU */
const PROMO_EVENTS_RU = {
  [EVENT_CODES.CYBER_WEEK_23]: {
    code: EVENT_CODES.CYBER_WEEK_23,
    schedule: {
      start: '2023-01-25T00:00:00Z',
      end: '2023-02-07T23:59:59Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.VALENTINES_23]: {
    code: EVENT_CODES.VALENTINES_23,
    schedule: {
      start: '2023-02-06T21:00:00Z',
      end: '2023-02-15T21:00:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.FEBRUARY_23]: {
    code: EVENT_CODES.FEBRUARY_23,
    schedule: {
      start: '2023-02-15T21:00:01Z',
      end: '2023-02-24T21:00:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.WOMENS_DAY_23]: {
    code: EVENT_CODES.WOMENS_DAY_23,
    schedule: {
      start: '2023-02-24T21:01:01Z',
      end: '2023-03-13T00:00:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.MARCH_PROMO_23]: {
    code: EVENT_CODES.MARCH_PROMO_23,
    schedule: {
      start: '2023-03-20T21:00:01Z',
      end: '2023-03-31T21:00:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.HEALTH_DAY_23]: {
    code: EVENT_CODES.HEALTH_DAY_23,
    schedule: {
      start: '2023-04-03T00:00:00Z',
      end: '2023-04-12T23:59:59Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.NEW_REC_23]: {
    code: EVENT_CODES.NEW_REC_23,
    schedule: {
      start: '2023-04-13T00:00:00Z',
      end: '2023-04-18T23:59:59Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.DNA_DAY_23]: {
    code: EVENT_CODES.DNA_DAY_23,
    schedule: {
      start: '2023-04-19T00:00:00Z',
      end: '2023-04-30T23:59:59Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.MAY_PROMO_23]: {
    code: EVENT_CODES.MAY_PROMO_23,
    schedule: {
      start: '2023-05-01T00:00:00Z',
      end: '2024-05-13T07:00:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.CHILD_DAY_23]: {
    code: EVENT_CODES.CHILD_DAY_23,
    schedule: {
      start: '2024-05-27T07:00:00Z',
      end: '2024-06-05T23:59:59Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.BIOME_DAY_23]: {
    code: EVENT_CODES.BIOME_DAY_23,
    schedule: {
      start: '2024-06-24T00:07:00Z',
      end: '2024-07-01T00:07:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.FAMILY_DAY_23]: {
    code: EVENT_CODES.FAMILY_DAY_23,
    schedule: {
      start: '2024-07-04T07:00:00Z',
      end: '2024-07-11T06:59:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.AUGUST_23]: {
    code: EVENT_CODES.AUGUST_23,
    schedule: {
      start: '2023-08-01T08:00:00Z',
      end: '2024-08-15T07:00:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.WOMENS_DAY_24]: {
    code: EVENT_CODES.WOMENS_DAY_24,
    schedule: {
      start: '2024-03-02T00:00:00Z',
      end: '2024-03-11T00:08:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.HEALTH_DAY_24]: {
    code: EVENT_CODES.HEALTH_DAY_24,
    schedule: {
      start: '2024-04-01T00:07:00Z',
      end: '2024-04-15T00:07:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.DNA_DAY_24]: {
    code: EVENT_CODES.DNA_DAY_24,
    schedule: {
      start: '2024-04-22T07:00:00Z',
      end: '2024-04-29T07:00:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.KNOWLEDGE_DAY_24]: {
    code: EVENT_CODES.KNOWLEDGE_DAY_24,
    schedule: {
      start: '2024-08-26T07:00:00Z',
      end: '2024-09-06T07:00:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.TEACHERS_DAY_24]: {
    code: EVENT_CODES.TEACHERS_DAY_24,
    schedule: {
      start: '2024-10-01T07:00:00Z',
      end: '2024-10-15T07:00:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.BLACK_FRIDAY_24]: {
    code: EVENT_CODES.BLACK_FRIDAY_24,
    schedule: {
      start: '2024-11-22T07:00:00Z',
      end: '2024-12-01T07:00:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.PRE_NEW_YEAR_24]: {
    code: EVENT_CODES.PRE_NEW_YEAR_24,
    schedule: {
      start: '2024-12-13T07:00:00Z',
      end: '2025-01-09T07:00:00Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.CYBER_MONDAY_25]: {
    code: EVENT_CODES.CYBER_MONDAY_25,
    schedule: {
      start: '2025-01-21T15:00:00Z',
      end: '2025-02-03T07:00:00Z',
    },
    countries: [RU],
  },
}
/** @type {Object.<string, PromoEvent>} - События EU */
const PROMO_EVENTS_EU = {
  [EVENT_CODES.VALENTINES_23]: {
    code: EVENT_CODES.VALENTINES_23,
    schedule: {
      start: '2023-02-08T00:00:00Z',
      end: '2023-02-17T00:00:00Z',
    },
    countries: [UK],
  },
  [EVENT_CODES.WOMENS_DAY_23]: {
    code: EVENT_CODES.WOMENS_DAY_23,
    schedule: {
      start: '2023-02-20T00:00:00Z',
      end: '2023-03-13T00:00:00Z',
    },
    withStyledProductPages: true,
    countries: [UK],
  },
  [EVENT_CODES.BIOME_BUNDLE_23]: {
    code: EVENT_CODES.BIOME_BUNDLE_23,
    schedule: {
      start: '2023-06-21T00:00:00Z',
      end: '2023-12-21T00:00:00Z',
    },
    withStyledProductPages: true,
    countries: [],
  },
}
/** @type {Object.<string, PromoEvent>} - События JP */
const PROMO_EVENTS_JP = {
}
/** @type {Object.<string, PromoEvent>} - События US */
const PROMO_EVENTS_US = {
  [EVENT_CODES.NEW_REC_23]: {
    code: EVENT_CODES.NEW_REC_23,
    schedule: {
      start: '2023-04-13T00:00:00Z',
      end: '2023-04-18T23:59:59Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.DNA_DAY_23]: {
    code: EVENT_CODES.DNA_DAY_23,
    schedule: {
      start: '2023-04-19T00:00:00Z',
      end: '2023-04-30T23:59:59Z',
    },
    countries: [RU],
  },
  [EVENT_CODES.MAY_PROMO_23]: {
    code: EVENT_CODES.MAY_PROMO_23,
    schedule: {
      start: '2023-05-01T00:00:00Z',
      end: '2024-05-13T07:00:00Z',
    },
    countries: [RU],
  },
}

// =================================
// Слайды для карусели
// =================================

/**
 * @typedef {{
 *   id: string,
 *   type: 'default' | 'promo',
 *   goodsType: string,
 *   event?: string,
 *   styles: {
 *     color: string,
 *     markColor?: string,
 *     bg: string,
 *     ignoreLocale?: boolean,
 *     imgPath: string,
 *     imgFormat: string,
 *   },
 *   countries: Array.<string>,
 * }} Slide
 *
 * @typedef {Array.<Slide>} Slides
 */

/**
 * Слайды для карусели RU
 * @type {Slides}
 */
const SLIDES_RU = [
  {
    id: 'dna',
    type: 'default',
    layout: 'default',
    goodsType: GOODS_TYPES.dna,
    styles: {
      bg: '#87CEB7',
      color: 'white',
      markColor: '#074E63',
      buttonBg: 'white',
      imgPath: 'dna',
      imgFormat: 'jpg',
    },
    countries: [RU],
  },
  {
    id: 'microbiome',
    type: 'default',
    layout: 'default',
    goodsType: GOODS_TYPES.microbiome,
    actionType: 'purchase',
    styles: {
      bg: '#EB9893',
      color: 'white',
      markColor: '#FFC5A7',
      buttonBg: 'white',
      imgPath: 'microbiome',
      imgFormat: 'jpg',
    },
    countries: [RU],
  },
  {
    id: 'wgs',
    type: 'default',
    layout: 'default',
    goodsType: GOODS_TYPES.wgs,
    actionType: 'purchase',
    styles: {
      bg: '#D9D9D9',
      color: 'white',
      markColor: 'black',
      buttonBg: 'white',
      imgPath: 'wgs',
      imgFormat: 'jpg',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.VALENTINES_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.VALENTINES_23,
    actionType: 'purchase',
    withDiscountBadge: false,
    styles: {
      bg: '#E85D74',
      color: 'white',
      markColor: '#C32A20',
      buttonBg: 'white',
      buttonColor: 'black',
      imgPath: `${EVENT_CODES.VALENTINES_23}`,
      imgFormat: 'png',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.FEBRUARY_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.FEBRUARY_23,
    actionType: 'purchase',
    withDiscountBadge: false,
    styles: {
      bg: '#6E7CC4',
      color: 'white',
      markColor: '#73E0FF',
      buttonBg: 'black',
      buttonColor: 'white',
      imgPath: `${EVENT_CODES.FEBRUARY_23}`,
      imgFormat: 'png',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.WOMENS_DAY_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.WOMENS_DAY_23,
    actionType: 'purchase',
    withDiscountBadge: false,
    styles: {
      bg: '#714232',
      color: 'white',
      markColor: '#FFAFD1',
      buttonBg: 'black',
      buttonColor: 'white',
      imgPath: `${EVENT_CODES.WOMENS_DAY_23}`,
      imgFormat: 'png',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.HEALTH_DAY_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.HEALTH_DAY_23,
    actionType: 'purchase',
    withDiscountBadge: true,
    styles: {
      bg: '#EBEFF0',
      color: 'black',
      markColor: '#7645EF',
      buttonBg: 'black',
      imgPath: `${EVENT_CODES.HEALTH_DAY_23}`,
      imgFormat: 'png',
      badgeBg: 'pink',
      badgeColor: 'white',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.NEW_REC_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.HEALTH_DAY_23,
    actionType: 'scroll',
    withDiscountBadge: true,
    styles: {
      bg: '#56565B',
      color: 'white',
      markColor: '#EAFC7A',
      buttonBg: 'white',
      imgPath: `${EVENT_CODES.NEW_REC_23}`,
      imgFormat: 'png',
      badgeBg: 'pink',
      badgeColor: 'white',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.NEW_REC_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.NEW_REC_23,
    actionType: 'scroll',
    withDiscountBadge: true,
    styles: {
      bg: '#56565B',
      color: 'white',
      markColor: '#EAFC7A',
      buttonBg: 'white',
      imgPath: `${EVENT_CODES.NEW_REC_23}`,
      imgFormat: 'png',
      badgeBg: 'pink',
      badgeColor: 'white',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.DNA_DAY_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.DNA_DAY_23,
    actionType: 'purchase',
    withDiscountBadge: true,
    styles: {
      bg: '#0A0028',
      color: 'white',
      markColor: '#7645EF',
      buttonBg: 'white',
      imgPath: `${EVENT_CODES.DNA_DAY_23}`,
      imgFormat: 'png',
      badgeBg: 'lightYellow',
      badgeColor: 'black',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.NEW_REC_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.DNA_DAY_23,
    actionType: 'scroll',
    withDiscountBadge: true,
    styles: {
      bg: '#56565B',
      color: 'white',
      markColor: '#EAFC7A',
      buttonBg: 'white',
      imgPath: `${EVENT_CODES.NEW_REC_23}`,
      imgFormat: 'png',
      badgeBg: 'pink',
      badgeColor: 'white',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.MAY_PROMO_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.MAY_PROMO_23,
    actionType: 'purchase',
    withDiscountBadge: true,
    styles: {
      bg: '#DEE0E5',
      color: 'black',
      markColor: '#E961A0',
      buttonBg: 'black',
      imgPath: `${EVENT_CODES.MAY_PROMO_23}`,
      imgFormat: 'png',
      badgeBg: 'pink',
      badgeColor: 'white',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.CHILD_DAY_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.CHILD_DAY_23,
    actionType: 'purchase',
    withDiscountBadge: true,
    styles: {
      bg: '#DED6E9',
      color: 'black',
      markColor: '#7645EF',
      buttonBg: 'black',
      imgPath: `${EVENT_CODES.CHILD_DAY_23}`,
      imgFormat: 'png',
      badgeBg: 'pink',
      badgeColor: 'white',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.BIOME_DAY_23}`,
    goodsType: GOODS_TYPES.microbiome,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.BIOME_DAY_23,
    actionType: 'purchase',
    withDiscountBadge: true,
    styles: {
      bg: '#E4D1C5',
      color: 'black',
      markColor: '#8950FF',
      buttonBg: 'black',
      imgPath: `${EVENT_CODES.BIOME_DAY_23}`,
      imgFormat: 'png',
      badgeBg: 'pink',
      badgeColor: 'white',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.FAMILY_DAY_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.FAMILY_DAY_23,
    actionType: 'purchase',
    withDiscountBadge: true,
    styles: {
      bg: '#FECC49',
      color: 'black',
      markColor: '#ffffff',
      buttonBg: 'black',
      imgPath: `${EVENT_CODES.FAMILY_DAY_23}`,
      imgFormat: 'png',
      badgeBg: 'pink',
      badgeColor: 'white',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.AUGUST_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.AUGUST_23,
    actionType: 'purchase',
    withDiscountBadge: true,
    styles: {
      bg: '#FF8AA2',
      color: 'white',
      markColor: '#ffcc01',
      buttonBg: 'white',
      imgPath: `${EVENT_CODES.AUGUST_23}`,
      imgFormat: 'png',
      badgeBg: '#F1F52F',
      badgeColor: 'black',
      badgeBorderColor: 'transparent',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.WOMENS_DAY_24}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.WOMENS_DAY_24,
    actionType: 'purchase',
    withDiscountBadge: true,
    discount: '11',
    styles: {
      bg: '#F9F4EE',
      color: 'black',
      markColor: '#8E8DFF',
      buttonBg: 'black',
      buttonColor: 'white',
      imgPath: `${EVENT_CODES.WOMENS_DAY_24}`,
      imgFormat: 'jpg',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.HEALTH_DAY_24}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.HEALTH_DAY_24,
    actionType: 'purchase',
    withDiscountBadge: true,
    styles: {
      bg: '#C8CAC9',
      color: 'white',
      markColor: '#7645EF',
      buttonBg: 'black',
      buttonColor: 'white',
      imgPath: `${EVENT_CODES.HEALTH_DAY_24}`,
      imgFormat: 'jpg',
      // badgeBg: '#C8CAC9',
      // badgeColor: '#7645EF',
      badgeBg: 'transparent',
      badgeColor: '#7645EF',
      labelBg: '#7645EF',
      badgeBorderColor: '#7645EF',
      // badgeBorderColor: '#7645EF',
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.DNA_DAY_24}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.DNA_DAY_24,
    actionType: 'purchase',
    withDiscountBadge: true,
    discount: '31',
    styles: {
      bg: '#232323',
      color: 'white',
      markColor: '#ff5b5d',
      buttonBg: 'white',
      buttonColor: 'black',
      imgPath: `${EVENT_CODES.DNA_DAY_24}`,
      imgFormat: 'jpg',
      badgeColor: 'white',
      badgeBg: '#FF68B9',
      badgeBorderColor: '#FF68B9',
      oldPriceColor: 'white'
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.KNOWLEDGE_DAY_24}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.KNOWLEDGE_DAY_24,
    actionType: 'purchase',
    withDiscountBadge: true,
    discount: '31',
    styles: {
      bg: '#CA7A4F',
      color: 'white',
      markColor: '#F1F52F',
      buttonBg: 'black',
      buttonColor: 'white',
      imgPath: `${EVENT_CODES.KNOWLEDGE_DAY_24}`,
      imgFormat: 'png',
      badgeColor: 'black',
      badgeBg: '#F1F52F',
      badgeBorderColor: '#F1F52F',
      oldPriceColor: 'white'
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.TEACHERS_DAY_24}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.TEACHERS_DAY_24,
    actionType: 'purchase',
    withDiscountBadge: true,
    discount: '31',
    styles: {
      bg: '#F5A7B6',
      color: 'white',
      markColor: '#EAFC7A',
      buttonBg: 'black',
      buttonColor: 'white',
      imgPath: `${EVENT_CODES.TEACHERS_DAY_24}`,
      imgFormat: 'png',
      badgeColor: 'black',
      badgeBg: '#EAFC7A',
      badgeBorderColor: '#EAFC7A',
      oldPriceColor: 'white'
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.BLACK_FRIDAY_24}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.BLACK_FRIDAY_24,
    actionType: 'purchase',
    withDiscountBadge: true,
    discount: '31',
    styles: {
      bg: '#131313',
      color: 'white',
      markColor: '#D0FF15',
      buttonBg: 'white',
      buttonColor: 'black',
      imgPath: `${EVENT_CODES.BLACK_FRIDAY_24}`,
      imgFormat: 'png',
      badgeColor: 'black',
      badgeBg: '#D0FF15',
      badgeBorderColor: '#D0FF15',
      oldPriceColor: 'white'
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.PRE_NEW_YEAR_24}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.PRE_NEW_YEAR_24,
    actionType: 'purchase',
    withDiscountBadge: true,
    discount: '31',
    styles: {
      bg: '#D33228',
      color: 'white',
      markColor: '#FFE060',
      buttonBg: 'white',
      buttonColor: 'black',
      imgPath: `${EVENT_CODES.PRE_NEW_YEAR_24}`,
      imgFormat: 'jpg',
      badgeColor: 'white',
      badgeBg: 'transparent',
      badgeBorderColor: 'white',
      oldPriceColor: 'white'
    },
    countries: [RU],
  },
  {
    id: `${EVENT_CODES.CYBER_MONDAY_25}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.CYBER_MONDAY_25,
    actionType: 'purchase',
    withDiscountBadge: true,
    discount: '31',
    styles: {
      bg: '#E4E9EA',
      color: 'black',
      markColor: '#00BEFF',
      buttonBg: 'black',
      buttonColor: 'white',
      imgPath: `${EVENT_CODES.CYBER_MONDAY_25}`,
      imgFormat: 'jpg',
      badgeColor: '#00BEFF',
      badgeBg: 'transparent',
      badgeBorderColor: '#00BEFF',
      oldPriceColor: 'white'
    },
    countries: [RU],
  },
]

/**
 * Слайды для карусели EU
 * @type {Slide[]}
 */
const SLIDES_EU = [
  {
    id: 'microbiome',
    type: 'default',
    layout: 'default',
    goodsType: GOODS_TYPES.microbiome,
    styles: {
      bg: '#63cedd',
      color: 'white',
      markColor: '#65FFDA',
      buttonBg: 'white',
      ignoreLocale: true,
      imgPath: 'microbiome',
      imgFormat: 'jpg',
    },
    countries: [UK, AT, BE, CA, DE, DK, FI, HU, IE, IT, LU, NL, NO, PL, SE, TR],
  },
  {
    id: 'dna',
    type: 'default',
    layout: 'default',
    goodsType: GOODS_TYPES.dna,
    styles: {
      bg: '#6b48cb',
      color: 'white',
      markColor: '#DDC3A2',
      buttonBg: 'white',
      ignoreLocale: true,
      imgPath: 'dna',
      imgFormat: 'jpg',
    },
    countries: [UK, BE, CA, DK, FI, HU, IE, IT, LU, NL, NO, PL, SE],
  },
  {
    id: `${EVENT_CODES.VALENTINES_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.VALENTINES_23,
    actionType: 'purchase',
    withDiscountBadge: false,
    styles: {
      bg: '#E85D74',
      color: 'white',
      markColor: '#C32A20',
      buttonBg: 'white',
      buttonColor: 'black',
      imgPath: `${EVENT_CODES.VALENTINES_23}`,
      imgFormat: 'png',
    },
    countries: [UK],
  },
  {
    id: `${EVENT_CODES.WOMENS_DAY_23}`,
    goodsType: GOODS_TYPES.dna,
    type: 'promo',
    layout: 'promo',
    event: EVENT_CODES.WOMENS_DAY_23,
    actionType: 'purchase',
    withDiscountBadge: false,
    styles: {
      bg: '#714232',
      color: 'white',
      markColor: '#FFAFD1',
      buttonBg: 'black',
      buttonColor: 'white',
      imgPath: `${EVENT_CODES.WOMENS_DAY_23}`,
      imgFormat: 'png',
    },
    countries: [UK],
  },
]

/**
 * Слайды для карусели JP
 * @type {Slide[]}
 */
const SLIDES_JP = [
  {
    id: 'dna',
    type: 'default',
    goodsType: GOODS_TYPES.dna,
    styles: {
      color: 'white',
      markColor: '#DDC3A2',
      bg: '#6b48cb',
      ignoreLocale: true,
      imgPath: 'dna',
      imgFormat: 'jpg',
    },
    countries: [JP],
  },
  {
    id: 'microbiome',
    type: 'default',
    goodsType: GOODS_TYPES.microbiome,
    styles: {
      color: 'white',
      markColor: '#65FFDA',
      bg: '#63cedd',
      ignoreLocale: true,
      imgPath: 'microbiome',
      imgFormat: 'jpg',
    },
    countries: [JP],
  },
]

/**
 * Слайды для карусели US
 * @type {Slides}
 */
const SLIDES_US = [
  {
    id: 'microbiome',
    type: 'default',
    layout: 'promo',
    withDiscountBadge: true,
    goodsType: GOODS_TYPES.microbiome,
    actionType: 'purchase',
    styles: {
      bg: '#63CEDD',
      color: 'white',
      markColor: '#6EFEF5',
      buttonBg: 'white',
      imgPath: 'microbiome',
      imgFormat: 'png',
      badgeBg: 'pink',
      badgeColor: 'white',
    },
    countries: [US],
  },
]

// =================================
// Баннеры под шапкой
// =================================

const SALES_BANNER_RU = {
  [EVENT_CODES.CYBER_WEEK_23]: {
    name: EVENT_CODES.CYBER_WEEK_23,
    start: PROMO_EVENTS_RU[EVENT_CODES.CYBER_WEEK_23].schedule.start,
    end: PROMO_EVENTS_RU[EVENT_CODES.CYBER_WEEK_23].schedule.end,
    isClosing: true,
    color: 'pistachio',
    btnColor: 'black',
    textColor: 'black',
    img: 'rabbit',
    imgType: 'svg',
    link: '/cart?action=fill-cart&dna=1&microbiome=1',
    imgSizes: {
      xs: 90,
      md: 83,
      lg: 83,
    },
    height: {
      xs: 142,
      md: 66,
      lg: 66,
    },
    exclude: [],
    closeButtonColor: 'black',
  },
  [EVENT_CODES.MARCH_PROMO_23]: {
    name: EVENT_CODES.MARCH_PROMO_23,
    start: PROMO_EVENTS_RU[EVENT_CODES.MARCH_PROMO_23].schedule.start,
    end: PROMO_EVENTS_RU[EVENT_CODES.MARCH_PROMO_23].schedule.end,
    isClosing: true,
    color: 'pink',
    btnColor: 'black',
    textColor: 'white',
    img: 'lightning',
    imgType: 'png',
    link: '/cart?action=fill-cart&dna=1&microbiome=1',
    imgSizes: {
      xs: 90,
      md: 83,
      lg: 83,
    },
    height: {
      xs: 142,
      md: 66,
      lg: 66,
    },
    exclude: [],
    closeButtonColor: 'white',
  },
  [EVENT_CODES.HEALTH_DAY_23]: {
    name: EVENT_CODES.NEW_REC_23,
    start: PROMO_EVENTS_RU[EVENT_CODES.HEALTH_DAY_23].schedule.start,
    end: PROMO_EVENTS_RU[EVENT_CODES.HEALTH_DAY_23].schedule.end,
    isClosing: true,
    color: '#CDBCFA',
    btnColor: 'black',
    textColor: 'black',
    img: 'newRec23',
    imgType: 'png',
    link: '',
    imgSizes: {
      xs: 120,
      md: 120,
      lg: 129,
    },
    height: {
      xs: 140,
      md: 66,
      lg: 66,
    },
    exclude: [],
    closeButtonColor: 'black',
  },
  [EVENT_CODES.NEW_REC_23]: {
    name: EVENT_CODES.NEW_REC_23,
    start: PROMO_EVENTS_RU[EVENT_CODES.NEW_REC_23].schedule.start,
    end: PROMO_EVENTS_RU[EVENT_CODES.NEW_REC_23].schedule.end,
    isClosing: true,
    color: '#CDBCFA',
    btnColor: 'black',
    textColor: 'black',
    img: 'newRec23',
    imgType: 'png',
    link: '',
    imgSizes: {
      xs: 120,
      md: 120,
      lg: 129,
    },
    height: {
      xs: 140,
      md: 66,
      lg: 66,
    },
    exclude: [],
    closeButtonColor: 'black',
  },
  ["dnaFit24"]: {
    name: "dnaFit24",
    start: '2024-07-16T23:59:59Z',
    end: '2024-08-31T23:59:59Z',
    isClosing: true,
    color: 'pistachio',
    btnColor: 'black',
    textColor: 'black',
    img: 'rabbit',
    imgType: 'svg',
    link: 'https://sport.atlas.ru',
    imgSizes: {
      xs: 90,
      md: 83,
      lg: 83,
    },
    height: {
      xs: 142,
      md: 66,
      lg: 66,
    },
    exclude: [],
    closeButtonColor: 'black',
  },
  ["affiliateProgram"]: {
    name: "affiliateProgram",
    start: '2024-11-12T00:00:00Z',
    end: '2024-12-31T23:59:59Z',
    isClosing: true,
    color: 'pistachio',
    btnColor: 'black',
    textColor: 'black',
    img: 'slapstick',
    imgType: 'png',
    link: 'https://promo.atlas.ru',
    imgSizes: {
      xs: 90,
      md: 83,
      lg: 83,
    },
    height: {
      xs: 142,
      md: 66,
      lg: 66,
    },
    exclude: [],
    closeButtonColor: 'black',
  },
}
const SALES_BANNER_EU = {
  [EVENT_CODES.VALENTINES_23]: {
    name: EVENT_CODES.VALENTINES_23,
    start: PROMO_EVENTS_EU[EVENT_CODES.VALENTINES_23].schedule.start,
    end: PROMO_EVENTS_EU[EVENT_CODES.VALENTINES_23].schedule.end,
    isClosing: false,
    color: '#FF5C61',
    btnColor: 'white',
    textColor: 'white',
    img: 'hearts',
    imgType: 'png',
    imgSizes: {
      xs: 90,
      md: 83,
      lg: 83,
    },
    height: {
      xs: 142,
      md: 66,
      lg: 66,
    },
    productType: GOODS_TYPES.dna,
    exclude: [CA, DE, SE, LU, DK, FI, AT, PL, HU, NO, TR, IT, BE, NL, IE],
  },
  [EVENT_CODES.WOMENS_DAY_23]: {
    name: EVENT_CODES.WOMENS_DAY_23,
    start: PROMO_EVENTS_EU[EVENT_CODES.WOMENS_DAY_23].schedule.start,
    end: PROMO_EVENTS_EU[EVENT_CODES.WOMENS_DAY_23].schedule.end,
    isClosing: false,
    color: 'yellowSun',
    btnColor: 'black',
    textColor: 'black',
    img: 'sun',
    imgType: 'png',
    imgSizes: {
      xs: 90,
      md: 83,
      lg: 83,
    },
    height: {
      xs: 142,
      md: 66,
      lg: 66,
    },
    productType: GOODS_TYPES.dna,
    exclude: [CA, DE, SE, LU, DK, FI, AT, PL, HU, NO, TR, IT, BE, NL, IE],
  },
  [EVENT_CODES.BIOME_BUNDLE_23]: {
    name: EVENT_CODES.BIOME_BUNDLE_23,
    start: PROMO_EVENTS_EU[EVENT_CODES.BIOME_BUNDLE_23].schedule.start,
    end: PROMO_EVENTS_EU[EVENT_CODES.BIOME_BUNDLE_23].schedule.end,
    isClosing: false,
    color: 'yellowSun',
    btnColor: 'black',
    textColor: 'black',
    img: 'bundle_biome',
    link: '/cart?product=biome_bundle',
    imgType: 'png',
    imgSizes: {
      xs: 80,
      md: 83,
      lg: 83,
    },
    height: {
      xs: 142,
      md: 66,
      lg: 66,
    },
    productType: GOODS_TYPES.biome,
    exclude: [CA, DE, SE, LU, DK, FI, AT, PL, HU, NO, TR, IT, BE, NL, IE],
  },
}
const SALES_BANNER_JP = {
}

// =================================
// ? Какие-то дискаунты
// =================================

// UTC time
const DISCOUNTS_RU = {
  [DISCOUNTS.BOILERPLATE]: {
    name: DISCOUNTS.BOILERPLATE,
    start: '2021-11-10T00:00:00Z',
    end: '2021-11-29T20:59:59Z',
    theme: 'blackFriday',
    goods: [GOODS_TYPES.dna, GOODS_TYPES.biome],
  },
}
const DISCOUNTS_EU = {
  [DISCOUNTS.BOILERPLATE]: {
    name: DISCOUNTS.BOILERPLATE,
    start: '2021-10-12T00:00:00Z',
    end: '2021-10-31T23:59:59Z',
    promoDiscount: 0,
    exclude: [CA, DE, SE, NL, BE, LU, DK, FI, IE, AT, PL, HU, NO, TR, IT],
    goods: [GOODS_TYPES.dna],
  },
}
const DISCOUNTS_JP = {
  [DISCOUNTS.BOILERPLATE]: {
    name: DISCOUNTS.BOILERPLATE,
    start: '2021-12-14T15:00:00Z',
    end: '2021-12-25T14:59:59Z',
    goods: [GOODS_TYPES.dna, GOODS_TYPES.biome],
  },
}

module.exports = {
  SALES_RUS,
  SALES_UK,
  SALES_EU,
  SALES_CA,
  SALES_DK,
  SALES_HU,
  SALES_JP,
  GOODS_TYPES,
  SALES,
  COUNTRIES_WITHOUT_DNA,
  COUNTRIES_WITHOUT_SALES,
  COUNTRIES_WITH_DISTRIBUTION,
  COUNTRIES_WITHOUT_EMAIL_SUBSCRIPTION,
  COUNTRIES_WITH_DYNAMIC_DISCOUNT,
  DELIVERY_TYPES,
  STRIPE_COUNTRY,
  SALES_BANNER_RU,
  SALES_BANNER_EU,
  SALES_BANNER_JP,
  DYNAMIC_DISCOUNT_PROMO_EU,
  DYNAMIC_DISCOUNT_PROMO_RU,
  DYNAMIC_DISCOUNT_PROMO_JP,
  DISCOUNTS_RU,
  DISCOUNTS_EU,
  DISCOUNTS_JP,
  DISCOUNTS,

  // Использовать только их, все остальное остается для обратной совместимости.
  EVENT_CODES,
  PROMO_EVENTS_RU,
  PROMO_EVENTS_EU,
  PROMO_EVENTS_JP,
  PROMO_EVENTS_US,
  SLIDES_RU,
  SLIDES_EU,
  SLIDES_JP,
  SLIDES_US,
}
